/* src/screens/NeighborhoodScreen.css */
.neighborhood-screen {
    background-image: url('../assets/images/guesthouseinrio.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden; /* Hide overflow for the entire screen */
}

.overlay {
    position: fixed; /* Cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    z-index: 1;
}

.content-container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for text */
    padding: 30px;
    border-radius: 10px;
    max-width: 900px;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    margin: 20px;
    max-height: calc(100vh - 40px); /* Ensure it fits within the viewport height */
    overflow-y: auto; /* Handle vertical overflow */
    overflow-x: hidden; /* Hide horizontal overflow */
}

h1 {
    color: #e94e77; /* Main title color */
    font-size: 2.5rem;
    margin-bottom: 20px;
}

h2 {
    color: #f9c74f; /* Subsection title color */
    font-size: 2rem;
    margin-top: 30px;
    margin-bottom: 15px;
}

h3 {
    color: #90be6d; /* Subsection color */
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
}

h4 {
    color: #43aa8b; /* Additional section color */
    font-size: 1.25rem;
    margin-top: 15px;
    margin-bottom: 10px;
}

p {
    margin: 15px 0;
    line-height: 1.6;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    margin: 15px 0;
}

strong {
    color: #f9c74f; /* Highlight important labels */
}

iframe {
    border: 0;
    border-radius: 10px;
    margin: 20px 0;
    width: 100%;
    max-width: 100%;
    height: 400px; /* Fixed height for maps */
}

@media (max-width: 768px) {
    .content-container {
        padding: 15px;
        max-width: 95%;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    h4 {
        font-size: 1rem;
    }
}
