.hero-container {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.hero-content {
    position: relative;
    z-index: 1;
    max-width: 600px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
}

.cta-button {
    padding: 10px 20px;
    background: #f39c12;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
}

.cta-button:hover {
    background: #e67e22;
}

.additional-info {
    margin-top: 20px;
    font-size: 18px;
}
