body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #F7F7F7;
    margin: 0;
    padding: 0;
}

.header {
    background-color: #002F5D;
    color: #FFF;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.header .logo {
    font-size: 24px;
    font-weight: bold;
}

.header .nav {
    display: flex;
    gap: 20px;
}

.header .nav a {
    color: #FFF;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
}

.header .nav a:hover {
    color: #FFD700;
}

.hero {
    background-image: url('./assets/images/guesthouseinrio.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    position: relative;
}

.hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.hero-content {
    position: relative;
    z-index: 1;
}

.hero h1 {
    font-size: 48px;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero button {
    background-color: #FFD700;
    border: none;
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    color: #002F5D;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.3s;
}

.hero button:hover {
    background-color: #FFC700;
    transform: scale(1.05);
}

.section {
    padding: 60px 20px;
    background-color: #FFF;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #002F5D;
    text-align: center;
}

.section p, .section ul {
    font-size: 18px;
    line-height: 1.6;
}

.footer {
    background-color: #002F5D;
    color: #FFF;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer p {
    margin: 0;
}
