/* src/screens/DescriptionScreen.css */
.description-screen {
    background-image: url('../assets/images/guesthouseinrio.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.content-container {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
}

.converted-prices {
    margin-top: 20px;
}

.converted-prices p {
    margin: 5px 0;
}
