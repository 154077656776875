/* src/screens/MediaScreen.css */

.media-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../assets/images/guesthouseinrio.jpg'); /* Background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  padding: 20px;
}

.media-content {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.gallery-image {
  width: 100%;
  height: auto;
  max-width: 200px; /* Adjust size as needed */
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .media-content {
    padding: 10px;
  }

  .image-gallery {
    flex-direction: column;
  }
}
