/* src/screens/ContactScreen.css */

/* Ensure html and body use the full viewport height */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; /* Prevent scroll bars */
    box-sizing: border-box;
}

/* Contact screen should cover the full viewport height */
.contact-screen {
    background-image: url('../assets/images/guesthouseinrio.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    min-height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0; /* Remove padding */
    box-sizing: border-box;
    overflow: hidden; /* Prevent scroll bars */
}

/* Content container adjustments */
.content-container {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
}

/* Form elements adjustments */
form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input, textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

button {
    padding: 10px;
    background-color: #e94e77;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:disabled {
    background-color: grey;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contact-screen {
        padding: 15px;
    }

    .content-container {
        padding: 15px;
        max-width: 90%;
    }
}
