/* src/components/Header.css */
.header {
    background-color: #282c34; /* Dark background for a modern look */
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 15px 0;
}

.header-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.nav {
    display: flex;
    gap: 20px;
    margin-bottom: 10px; /* Add margin below the nav */
}

.nav a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    position: relative; /* For pseudo-element positioning */
    padding-bottom: 10px; /* Space for the underline */
    transition: color 0.3s ease;
}

.nav a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #e94e77; /* Red line */
    transition: width 0.3s ease;
}

.nav a:hover::before,
.nav a.active::before {
    width: 100%;
}

.nav a:hover,
.nav a.active {
    color: #e94e77; /* Red color for text on hover or active */
}

.language-selector {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.flag-icon {
    width: 24px;
    height: 16px;
    cursor: pointer;
    transition: transform 0.3s ease, border 0.3s ease;
}

.flag-icon.active {
    border: 2px solid #e94e77; /* Highlight border for active flag */
    border-radius: 4px;
    transform: scale(1.2); /* Slightly enlarge active flag */
}
